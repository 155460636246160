import '@styles/components/dropdown.css'
import easydropdown from 'easydropdown-a11y'
import { on, off, fire } from 'delegated-events'
import throttle from 'lodash/throttle'
import { createUrl } from '@common/util'
import scrollTo from '@common/scroll-to'
import ajax from '@common/ajax'

const selectors = {
  pageFilters: '[data-page-filters]',
  pageFiltersClear: '[data-page-filters-clear]',
}

const resetForm = (form) => {
  form.querySelectorAll('input').forEach((input) => (input.value = null))
  form.querySelectorAll('select').forEach((select) => (select.selectedIndex = -1))
}

const handleClear = (e) => {
  e.preventDefault()

  const filters = e.target.closest(selectors.pageFilters)

  filters.querySelectorAll('form').forEach((form) => resetForm(form))

  window.history.replaceState({}, '', e.target.href)

  fire(document.documentElement, 'pagefilters:update', { filtersEl: filters, url: window.location.href, type: 'clear' })

  filters.classList.remove('is-filtered')
}

const handleFormSubmit = (e) => {
  const form = e.target.closest('form')
  const filters = form.closest(selectors.pageFilters)

  // Only one of the filters is active at a time, so clear all the other ones
  if (filters.dataset.pageFilters === 'single') {
    Array.from(filters.querySelectorAll('form'))
      .filter((x) => x !== form)
      .forEach((form) => resetForm(form))
  }

  // Allow one of the filters active along with search
  if (filters.dataset.pageFilters === 'singlesearch') {
    Array.from(filters.querySelectorAll('form'))
      .filter((x) => x !== form)
      .forEach((otherForm) => {
        if (form.dataset.submit && form.dataset.submit === 'append') {
          const values = Array.from(new FormData(otherForm).values()).filter((x) => x)

          if (!values.length) {
            resetForm(otherForm)
          }
        } else {
          resetForm(otherForm)
        }
      })
  }

  // Fully replace the url with the given value
  if (form.dataset.submit && form.dataset.submit === 'replace') {
    e.preventDefault()

    const field = form[0]

    if (field) {
      window.history.replaceState({}, '', field.value)

      fire(document.documentElement, 'pagefilters:update', {
        filtersEl: filters,
        url: window.location.href,
        type: 'replace',
        data: new FormData(form),
      })
    }
  }

  // Fully replace the url with the given value, but as a query param
  if (form.dataset.submit && form.dataset.submit === 'get') {
    e.preventDefault()

    const data = new FormData(form)
    const url = createUrl(form.action, data)

    window.history.replaceState({}, '', url)

    fire(document.documentElement, 'pagefilters:update', { filtersEl: filters, url: window.location.href, type: 'get' })
  }

  // Fully replace the url with the given value, but as a query param, fired by keyup
  if (form.dataset.submit && form.dataset.submit === 'live') {
    e.preventDefault()

    const data = new FormData(form)
    const query = [...data.entries()].reduce((acc, data) => {
      if (data[1] !== '') {
        acc[data[0]] = data[1]
      }

      return acc
    }, {})

    const params = new URLSearchParams(query)
    const hasParams = [...params.entries()].length > 0

    const url = hasParams ? new URL(`${form.action}?${params}`) : new URL(`${form.action}`)

    window.history.replaceState({}, '', url)

    fire(document.documentElement, 'pagefilters:update', {
      filtersEl: filters,
      url: window.location.href,
      type: 'live',
    })

    if (hasParams) {
      filters.classList.add('is-filtered')
    } else {
      filters.classList.remove('is-filtered')
    }

    return
  }

  // Use the current url and update the query params
  if (form.dataset.submit && form.dataset.submit === 'append') {
    e.preventDefault()

    const data = new FormData(form)
    const url = createUrl(window.location, data, true)

    window.history.replaceState({}, '', url)

    fire(document.documentElement, 'pagefilters:update', {
      filtersEl: filters,
      url: window.location.href,
      type: 'append',
    })
  }

  // Use the given value to navigate somewhere
  if (form.dataset.submit && form.dataset.submit === 'navigate') {
    e.preventDefault()

    const field = form[0]

    if (field) {
      const target = new URL(field.value)

      if (window.location.origin === target.origin) {
        window.location.href = field.value
      } else {
        window.open(field.value, '_blank')

        field.selectedIndex = -1
      }

      return
    }
  }

  filters.classList.add('is-filtered')
  scrollTo(filters, 48)
}

const handleDropdownChange = (e) => {
  const parentForm = e.target.closest('form')

  if (parentForm) {
    if (parentForm.requestSubmit) {
      parentForm.requestSubmit()
    } else {
      parentForm.dispatchEvent(new Event('submit'))
    }
  }
}

const handleUpdate = async (e) => {
  const filtersEl = e.detail.filtersEl

  if (filtersEl.dataset.pageFiltersUpdate === 'replace') {
    const pageContent = document.querySelector('[data-page-content]')

    if (pageContent) {
      pageContent.classList.add('is-loading')

      const res = await ajax.get(e.detail.url).text()

      pageContent.innerHTML = res
      pageContent.classList.remove('is-loading')

      fire(document.documentElement, 'pagecontent:replace')
    }

    if (filtersEl.dataset.pageFiltersRefetch !== undefined) {
      const queryType = e.detail.url.indexOf('?') === -1 ? '?' : '&'
      const res = await ajax.get(`${e.detail.url}${queryType}fetchPageFilters=1`).text()

      const filtersContent = filtersEl.querySelector('#page-filters')

      if (filtersContent) {
        filtersContent.innerHTML = res

        setup(true)
      }
    }

    e.stopPropagation()
  }
}

const setupDropdown = (el) => {
  if (el.tagName !== 'SELECT') {
    return
  }

  const placeholder = el.querySelector('option[value=""]')

  if (placeholder && !placeholder.hasAttribute('data-placeholder')) {
    placeholder.setAttribute('data-placeholder', true)
  }

  const opts = {
    behavior: {
      openOnFocus: true,
      maxVisibleItems: 15,
      liveUpdates: true,
      useNativeUiOnMobile: false,
    },
  }

  easydropdown(el, opts)
}

const setupPageFilters = (el) => {
  el.querySelectorAll('select').forEach((el) => setupDropdown(el))
}

const setup = (reInit) => {
  const els = document.querySelectorAll(selectors.pageFilters)

  const submitArgs = [
    'submit',
    `${selectors.pageFilters} form:not([data-submit="live"])`,
    handleFormSubmit,
    { capture: true },
  ]
  const keyArgs = [
    'keyup',
    `${selectors.pageFilters} form[data-submit="live"]`,
    throttle((e) => handleFormSubmit(e), 350),
    { capture: true },
  ]
  const changeArgs = ['change', `${selectors.pageFilters} select`, handleDropdownChange]
  const clearArgs = ['click', selectors.pageFiltersClear, handleClear]
  const updateArgs = ['pagefilters:update', 'html', handleUpdate]

  // Sometimes we refresh the html and need to remove the old listeners before adding them again
  if (reInit) {
    off(...submitArgs)
    off(...keyArgs)
    off(...changeArgs)
    off(...clearArgs)
    off(...updateArgs)
  }

  on(...submitArgs)
  on(...keyArgs)
  on(...changeArgs)
  on(...clearArgs)
  on(...updateArgs)

  els.forEach((el) => setupPageFilters(el))
}

setup()

on('pagefilters:reinit', 'html', () => setup(true))
